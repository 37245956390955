<template>
  <div>
    <v-row
      class="match-height"
      style="margin-bottom: 2%;"
    >
      <v-col
        cols="12"
        sm="12"
      >
        <!--        <statistics-card-vertical-->
        <!--          :color="totalProfit.color"-->
        <!--          :icon="totalProfit.icon"-->
        <!--          :statistics="stay_payment_list.length"-->
        <!--          :stat-title="totalProfit.statTitle"-->
        <!--          :subtitle="totalProfit.subtitle"-->
        <!--        ></statistics-card-vertical>-->
      </v-col>
    </v-row>
    <!--    show-expand-->
    <!--    :single-expand="singleExpand"-->
    <!--    :expanded.sync="expanded"-->
    <v-data-table
      :headers="headers"
      :items="stay_payment_list"
      item-key="full_name"
      class="table-rounded"
      :search="search"
    >
      <template #top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Liste des Payement de Sejour</v-toolbar-title>
        </v-toolbar>
        <v-spacer></v-spacer>
        <v-row style="margin-left: 1.5rem; margin-right: 1.5rem;">
          <!--          class="d-flex"-->
          <v-col
            cols="12"
            sm="4"
          >
          </v-col>
          <!--          class="d-flex"-->
          <v-col
            cols="12"
            sm="4"
          >
            <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="createStayPayment=true"
            >
              Nouveau Paiement
            </v-btn>
          </v-col>
          <!--          class="d-flex"-->
          <v-col
            cols="12"
            sm="4"
          >
            <v-text-field
              v-model="search"
              dense
              append-icon="mdi-magnify"
              label="Rechercher un Payment"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.stay="{item}">
        N: {{item.stay.id}} du {{item.stay.stays_date}} ({{ item.stay.stays_pay_off ? 'solde': 'non-solde'}})
      </template>
    </v-data-table>
    <!--    dialog to create client begin-->
    <template>
      <v-row justify="center">
        <v-dialog
          v-model="createStayPayment"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar
              dark
              color="primary"
            >
              <v-btn
                color="primary"
                @click="createStayPayment = false"
              >
                <mdi-close-circle />Annuler
              </v-btn>&emsp;
              <v-toolbar-title color="white">
                Enregistrer un nouveau Paiement
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tab-item>
                <v-btn
                  color="primary"
                  @click="createStayPayment = false"
                >
                  <mdi-close-circle />Fermer
                </v-btn>&emsp;
              </v-tab-item>
            </v-toolbar>
            <StayPaymentCreate @success-pay="closePay"></StayPaymentCreate>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </div>
</template>

<script>
import { mdiAccount, mdiDotsVertical, mdiSquareEditOutline } from '@mdi/js'
// eslint-disable-next-line import/extensions,import/no-unresolved
import StayPaymentCreate from '@/views/stay_payment/StayPaymentCreate'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Payment',
  components: { StayPaymentCreate },
  setup() {
    const totalProfit = {
      statTitle: 'Versement',
      icon: mdiAccount,
      color: 'success',
      subtitle: 'Versement enregistre',
    }

    return {
      headers: [
        { text: 'DATE DE PAIEMENT', value: 'pay_stay_date' },
        { text: 'REFERENCE DE PAIEMENT', value: 'pay_stay_ref' },
        { text: 'MONTANT', value: 'pay_stay_amount' },
        { text: 'SÉJOUR CONCERNE', value: 'stay' },
        { text: 'MODE DE PAIEMENT', value: 'payment_type.pay_typ_name' },

        // { text: 'APPARTEMENT CONCERNER', value: 'apart_concerning' },
        // { text: 'TYPE DE PAIEMENT', value: 'payment_types_id ' },
      ],
      totalProfit,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
      },
    }
  },
  data() {
    return {
      search: '',
      filtreTable: '',
      createStayPayment: false,
      stay_payment_list: [],
      stay_payment_backup: [],
    }
  },
  mounted() {
    this.loadPayment()
  },
  methods: {
    // filterFundTransaction() {
    //   this.stay_payment_list = this.stay_payment_list.filter(fdt => (fdt.stay_payoff === this.filtreTable)).length
    //   // eslint-disable-next-line radix
    //     ? this.stay_payment_list.filter(fdt => (fdt.cust_city === this.filtreTable)) : this.stay_payment_backup
    // },
    closePay() {
      this.createStayPayment = false
      this.loadPayment()
    },
    loadPayment() {
      this.$axios.get(this.$endpoint.LoadStayPayment).then(rp => {
        this.stay_payment_list = rp.data
        this.stay_payment_backup = rp.data
      })
    },
  },
}
</script>

<style scoped>

</style>
