<template>
  <div>
    <v-container>
      <v-row style="margin-top: 1%;">
        <v-col
            cols="12"
            md="6"
        >
          <v-select
              v-model="stay_payment.stay_id"
              dense
              :items="stay_list"
              :item-value="'id'"
              :item-text="item=>'ref:'+item.id+' ['+item.stays_date+']('+item.customer.fullname+')'"
              label="Sejour concerner"
              outlined
              @change="setAmountToPay"
          ></v-select>
        </v-col>
        <v-col
            cols="12"
            md="6"
        >
          <v-text-field
              v-model="amount_to_pay"
              dense
              :disabled="true"
              type="numeric"
              hide-details
              required
              label="Montant restant"
              outlined
          ></v-text-field>
        </v-col>
        <v-col
            cols="12"
            md="6"
        >
          <v-select
              v-model="stay_payment.payment_type_id"
              dense
              :items="payment_type_list"
              :item-value="'id'"
              :item-text="item=>item.pay_typ_name"
              label="Mode paiement"
              outlined
          ></v-select>
        </v-col>
        <v-col
            cols="12"
            md="6"
        >
          <v-text-field
              v-model="stay_payment.pay_stay_date"
              dense
              type="date"
              required
              hide-details
              label="Date de paiement"
              outlined
          ></v-text-field>
        </v-col>
        <v-col
            cols="12"
            md="6"
        >
          <v-text-field
              v-model="stay_payment.pay_stay_ref"
              dense
              hide-details
              label="Reference de paiement"
              outlined
          ></v-text-field>
        </v-col>
        <v-col
            cols="12"
            md="6"
        >
          <v-text-field
              v-model="stay_payment.pay_stay_amount"
              dense
              type="numeric"
              hide-details
              label="Montant du paiement"
              outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
            cols="12"
            md="6"
        >
        </v-col>
        <v-col
            cols="12"
            md="6"
        >
          <v-btn
              color="primary"
              @click="newStayPayement"
          >
            Enregistrer
          </v-btn>
</v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'StayPaymentCreate',
  data() {
    return {
      payment_type_list: [],
      stay_list: [],
      stay_backup: [],
      stay_payment: {
        pay_stay_date: '',
        pay_stay_ref: '',
        pay_stay_amount: 0,
        stay_id: 0,
        payment_type_id: 0,
      },
      amount_to_pay: 0,
    }
  },
  mounted() {
    this.loadPaymentType()
    this.loadStay()
  },
  methods: {
    loadPaymentType() {
      this.$axios.get(this.$endpoint.LoadPaymentType).then(rp => {
        this.payment_type_list = rp.data
        this.payment_type_backup = rp.data
      })
    },
    newStayPayement() {
      this.$axios.post(this.$endpoint.CreateStayPayment, this.stay_payment).then(rp => {
        console.log(rp)
        this.$emit('success-pay')
      })
    },
    setAmountToPay() {
      // eslint-disable-next-line prefer-destructuring
      this.amount_to_pay = this.stay_list.filter(st => st.id === this.stay_payment.stay_id)[0].stays_remaining_amount
    },
    loadStay() {
      this.$axios.get(this.$endpoint.LoadStayPayOffFalse).then(rp => {
        this.stay_list = rp.data
        this.stay_backup = rp.data
        console.log(this.stay_list)
      })
    },
  },
}
</script>

<style scoped>

</style>
